<template>
	<div>
	
		<b-modal
			v-model="addNewDivisionSwitch" :width="600">
			<template #default="props">
				<div class="modal-card" style="width: 600px;">
					<header class="modal-card-head">
						<p class="modal-card-title">Add New Division</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
					</header>
					<section class="modal-card-body">
						<b-field label="Enter a Division" vertical style="margin-top: 20px;">
							<b-select placeholder="Select Gender" v-model="addDivisionDetails.gender">
								<option value="mens" >Mens</option>
								<option value="ladies">Ladies</option>
								<option value="coed">Coed</option>
							</b-select>
							
							<b-select style="margin-left: 10px;" placeholder="Select Division" v-model="addDivisionDetails.rank_id">
								<option :value="division" :key="division.id" v-for="division in divisions">{{ division.name }}</option>
							</b-select>
						</b-field>
						<b-field label="Min Age" style="margin-top: 20px;">
							<b-numberinput type="is-danger" v-model="addDivisionDetails.min_age"></b-numberinput>
						</b-field>
						<b-field label="Max Age" style="margin-top: 20px;">
							<b-numberinput type="is-danger" v-model="addDivisionDetails.max_age"></b-numberinput>
						</b-field>
						<b-field label="Min # of Teams" style="margin-top: 20px;">
							<b-numberinput type="is-danger" v-model="addDivisionDetails.min_number_of_teams"></b-numberinput>
						</b-field>
						<b-field label="Max # of Teams" style="margin-top: 20px;">
							<b-numberinput type="is-danger" v-model="addDivisionDetails.max_number_of_teams"></b-numberinput>
						</b-field>

						<p class="note">Note: team changing gendered division will change their league insurance.</p>
						<div>
							<a role="button" v-on:click="addNewDivision()" style="margin-top: 10px;" class="admin-button">Add Division</a>
						</div>
						
					</section>
				</div>
			</template>
		</b-modal>
		<b-modal
			v-model="addTeamToEventSwitch" :width="900">
			<template #default="props">
				<div class="modal-card" style="width: 100%;">
					<header class="modal-card-head">
						<p class="modal-card-title">Find Team</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
					</header>
					<section class="modal-card-body">
						<div v-if="selectedTeam === null">
							<b-field label="Search for team" style="width: 100%;">
								<b-input v-model="searchForTeam" placeholder="Search by team name, team coach name or email..." v-on:input="searchForTeamFunction($event)"></b-input>
							</b-field>

							<b-field>
								<b-checkbox v-on:input="searchForTeamFunction()" v-model="selectRegion">Include out-of-region teams</b-checkbox>
							</b-field>

							<table class="table">
								<tr>
									<td></td>
									<td><b>Roster Season</b></td>
									<td><b>Team Name</b></td>
									<td><b>Division</b></td>
									<td><b>Coach Name</b></td>
									<td><b>City</b></td>
									<td><b>Region</b></td>
									
								</tr>
								
								<tr :key="team.id" v-for="team in searchResults">
									<td>
										<a class="button is-small" v-on:click="selectedTeam = team; registerForTournament.team = team">Add</a>
									</td>
									<td>
										<b-tag>{{ team.season }}</b-tag>
										<span v-for="insurance of team.insurance" :key="insurance.id">
											<b-tag type="is-primary" v-if="insurance.fully_paid === 1">{{ insurance.gender.toUpperCase() }} {{ insurance.season }} PAID</b-tag>
											<b-tag type="is-danger" v-if="insurance.fully_paid !== 1">UNPAID</b-tag>
										</span>
									</td>
									<td>
										{{ team.name }}
									</td>
									<td style="text-transform: uppercase;">
										<b-tag>{{ team.division_id }}</b-tag>
									</td>
									<td>
										{{ team.primary_coach.first_name }} {{ team.primary_coach.last_name }}
									</td>
									<td>
										{{ team.city }}
									</td>
									<td>
										{{ team.region.name }}
									</td>
									
								</tr>
							</table>
						</div>
						<div v-else>
							<a v-on:click="selectedTeam = null" class="button is-small">&lt; Select Different Team</a>
							
							<b-field label="Enter a Division" vertical style="margin-top: 20px;">
								<b-select placeholder="Select Gender" v-model="registerForTournament.gender">
									<option value="mens" v-if="totalDivisions.mens.length > 0">Mens</option>
									<option value="ladies" v-if="totalDivisions.ladies.length > 0">Ladies</option>
									<option value="coed" v-if="totalDivisions.coed.length > 0">Coed</option>
								</b-select>
								
								<b-select style="margin-left: 10px;" placeholder="Select Division" v-model="registerForTournament.division">
									<option :value="division" :key="division.id" v-for="division in totalDivisions[registerForTournament.gender]">{{ division.rank_id }}</option>
								</b-select>
							</b-field>
							<p><b>Please remind the team to go in and fill out their event roster after you have added them.</b></p>
							<a role="button" v-on:click="registerTeamToEvent()" style="margin-top: 10px;" class="admin-button">Register To The Event</a>
						</div>
						
					</section>
				</div>
			</template>
		</b-modal>

		<b-modal
			v-model="changeDivisionSwitch" :width="600">
			<template #default="props">
				<div class="modal-card" style="width: 600px;">
					<header class="modal-card-head">
						<p class="modal-card-title">Change Divisions</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
					</header>
					<section class="modal-card-body">
						<b-field label="Enter a Division" vertical style="margin-top: 20px;">
							<b-select placeholder="Select Gender" v-model="changeDivisionDetails.gender">
								<option value="mens" v-if="totalDivisions.mens.length > 0">Mens</option>
								<option value="ladies" v-if="totalDivisions.ladies.length > 0">Ladies</option>
								<option value="coed" v-if="totalDivisions.coed.length > 0">Coed</option>
							</b-select>
							
							<b-select style="margin-left: 10px;" placeholder="Select Division" v-model="changeDivisionDetails.division">
								<option :value="division" :key="division.id" v-for="division in totalDivisions[changeDivisionDetails.gender]">{{ division.rank_id }}</option>
							</b-select>
						</b-field>
						<p class="note">Note: team changing gendered division may affect their insurance (example: from mens to coed). When completing the change please review their insurance under the "Registered" column.</p>
						<div>
							<a role="button" v-on:click="moveTeamToNewDivision()" style="margin-top: 10px;" class="admin-button">Change Division</a>
						</div>
						
					</section>
				</div>
			</template>
		</b-modal>

		<Header  @update-lang="updateLanguage($event)" v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
            <div class="content-wrapper" v-if="tournament">
				<div :class="['page-header', { sidebarClosed: !content_width }]">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="page-header-title">
                                Manage {{ tournament.name }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="padding">
                                <b-field  grouped position="is-right">
                                    <a href="/dashboard/convenors/my-tournaments" class="button">Back to Convenor/League Central</a>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
				<div class="admin-area">
					<div  style="padding-bottom: 20px;"> 
						<b-tag type="is-large is-success" v-if="tournament.status === 'sanctioned'"><b>Current Status:</b> Approved</b-tag>
						<b-tag type="is-large is-warning" v-if="tournament.status === 'unapproved'"><b>Current Status:</b> Awaiting</b-tag>
						<b-tag type="is-large is-danger" v-if="tournament.status === 'rejected'"><b>Current Status:</b> Rejected</b-tag>
					</div>
					<span>
						<b-field label="Copy and share this link to advertise your event!" v-if="tournament.status === 'sanctioned'">
							<b-input :value="'https://slo-pitch.com/tournament/' + tournament.slug"></b-input>
						</b-field>
					</span>
					<div class="row">
						<div class="col-md-12">
							
							<a v-on:click="sendWaiversToTournament()" style="margin-top: 10px;" class="button is-small"><i class="fas fa-envelope-open-text"></i>&nbsp;&nbsp;&nbsp;Send Waiver Reminder to All Unsigned Players</a>
							<p style="margin-top: 10px;"><span v-if="tournament.last_waiver_reminder_sent"><b>Last time sent: </b> {{ moment(tournament.last_waiver_reminder_sent).format('YYYY-MM-DD') }}</span></p>
							
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="padding" style="padding-top: 0px;">
								<b-tabs>
									<b-tab-item label="Registered Teams">
										
										<RegisteredTeams 
											:eventId="tournament.id" 
											:eventSlug="tournament.slug" 
											:superadmin="false"
											:eventType="'tournament'">
										</RegisteredTeams>
									</b-tab-item>
									<b-tab-item label="Edit Tournament">
										<div class="row">
											<div class="col-md-3">
												<LeagueTournamentPreviewCard
													:event="tournament"
													:bottomHalf="true"
													:eventType="'tournament'"
													:posterUploadAbility="true">
												</LeagueTournamentPreviewCard>
											</div>
											<div class="col-md-9">
												<b-tabs v-on:input="updateAddressTab()">
													<b-tab-item label="Edit Basic Details">			
														<div class="row">
															<div class="col-md-4">
																<b-field label="League Name">
																	<b-input :disabled="editDisabled"  v-model="editTournament.name"></b-input>
																</b-field>
															</div>
															<div class="col-md-1"></div>
															<div class="col-md-4">
																<b-field label="League Name (FR)">
																	<b-input :disabled="editDisabled" v-model="editTournament.fr_name"></b-input>
																</b-field>
															</div>
															<div class="col-md-3">

															</div>
														</div>
														<div class="row row-margin">
															<div class="col-md-6">
																<b-field label="Tournament Start Date">
																	<b-datetimepicker
																		placeholder="Click to select..."
																		icon="calendar-today"
																		:disabled="editDisabled" 
																		v-model="editTournament.event_start_date"
																		horizontal-time-picker>
																	</b-datetimepicker>
																</b-field>
															</div>
															<div class="col-md-6">
																<b-field label="Tournament End Date">
																	<b-datetimepicker
																		placeholder="Click to select..."
																		icon="calendar-today"
																		:disabled="editDisabled" 
																		v-model="editTournament.event_end_date"
																		horizontal-time-picker>
																	</b-datetimepicker>
																</b-field>
															</div>
														</div>

														<div class="row row-margin">
															<div class="col-md-6">
																<b-field label="Registration Start Date">
																	<b-datetimepicker
																		placeholder="Click to select..."
																		icon="calendar-today"
																		
																		v-model="editTournament.registration_start_date"
																		horizontal-time-picker>
																	</b-datetimepicker>
																</b-field>
															
															</div>
															<div class="col-md-6">
																<b-field label="Registration End Date">
																	<b-datetimepicker
																		placeholder="Click to select..."
																		icon="calendar-today"
																		
																		v-model="editTournament.registration_end_date"
																		horizontal-time-picker>
																	</b-datetimepicker>
																</b-field>
															</div>
														</div>

														<div class="row row-margin">
															<div class="col-md-6">
																<b-field label="Roster Lock Date">
																	<b-datetimepicker
																		placeholder="Click to select..."
																		icon="calendar-today"
																		
																		v-model="editTournament.roster_lock_date"
																		horizontal-time-picker>
																	</b-datetimepicker>
																</b-field>
															</div>
														</div>

														<div class="row row-margin">
															<div class="col-md-6">
																<b-field label="Team Price">
																	<b-input 
																		v-model="editTournament.team_registration_fee"
																		
																		icon-pack="fas"
																		icon="dollar-sign"
																	></b-input>
																</b-field>
															</div>
															
														</div>
														<div class="row" style="margin-top:10px;">
															<div class="col-md-6">
																<b-field extended label="League Description" style="margin-right: 10px;"> 
																	<b-input style="width: 100%;" v-model="editTournament.description" maxlength="2000" type="textarea"></b-input>
																</b-field>
															</div>
														</div>

														<div class="row row-margin">
															<div class="col-md-6">
										
																<b-field label="Is this event invitation-only? Clicking yes will hide this event from our Tournament Calendar">
																	<div class="block">
																		<b-radio v-model="editTournament.invitation_only"
																			name="invitation_only"
																			:native-value="1">
																			Yes
																		</b-radio>
																		<b-radio v-model="editTournament.invitation_only"
																			name="invitation_only"
																			:native-value="null">
																			No
																		</b-radio>
																	</div>
																</b-field>
															</div>
															<div class="col-md-6">
																<b-field label="Max Roster Size per team (default is 16)">
																	<div class="block">
																		<b-numberinput v-model="editTournament.max_roster_size"></b-numberinput>
																	</div>
																</b-field>
															</div>	
														</div>


														<a class="admin-button" v-on:click="updateTournamentInfo()"><i class="fas fa-save"></i> Update Tournament Info</a>
													</b-tab-item>
													<b-tab-item label="Edit Divisions">
														<p class="note">You cannot delete a division until you have moved all teams from that division. Use the registered teams tab to change the division of the teams. When the team count for that division hits zero, the delete button will appear.</p>
														<a class="admin-button" 
														role="button"
														v-if="!editDisabled" v-on:click="addNewDivisionSwitch = true">Add a New Division</a>
														<div class="panel" style="margin-top: 20px;" v-for="division in editTournament.divisions" :key="division.id">
															<div class="panel-heading">
																<div class="row">
																	<div class="col-md-3">
																		{{ division.gender_slug.toUpperCase() }} {{ division.rank_id }}
																		<br>Team Count: {{ teamCount(division) }}
																	</div>
																	<div class="col-md-5">
																		<b-dropdown hoverable aria-role="list" v-model="division.qualifier">
																			<div class="button is-info" slot="trigger">
																				<span>
																					{{ division.qualifier }}
																				</span>
																				<b-icon icon="menu-down"></b-icon>
																			</div>

																			<b-dropdown-item value="Provincial Qualifer" aria-role="listitem">
																				Provincial Qualifer
																			</b-dropdown-item>
																			<b-dropdown-item value="National Qualifer" aria-role="listitem">
																				National Qualifer
																			</b-dropdown-item>
																			<b-dropdown-item value="Non-Qualifer" aria-role="listitem">
																				Non-Qualifer
																			</b-dropdown-item>
																		</b-dropdown>
																	</div>
															
																	<div class="col-md-4" v-if="teamCount(division) === 0">
																		<a class="button is-small is-success" v-on:click="updateDivision(division)">Update Division</a>
																		<a class="button is-small is-danger" v-on:click="deleteDivision(division)">Delete Division</a>
																	</div>
																</div>
															</div>
															<div class="panel-block" style="display: block;">
																<div class="row">
																	<div class="col-md-3">
																		<div class="padding">
																			<b-field label="Minimum # of Teams" min="3" custom-class="is-small">
																				<b-numberinput type="is-danger" v-model="division.min_number_of_teams"></b-numberinput>
																			</b-field>
																		</div>
																	</div>
																	<div class="col-md-3">
																		<div class="padding">
																			<b-field label="Max # of Teams" custom-class="is-small">
																				<b-numberinput type="is-danger" min="3" v-model="division.max_number_of_teams"></b-numberinput>
																			</b-field>
																		</div>
																	</div>
																	<div class="col-md-3">
																		<div class="padding">
																			<b-field label="Minimum Age" custom-class="is-small">
																				<b-numberinput type="is-danger" min="16" max="99" v-model="division.min_age"></b-numberinput>
																			</b-field>
																		</div>
																	</div>
																	<div class="col-md-3">
																		<div class="padding">
																			<b-field label="Maximum Age" custom-class="is-small">
																				<b-numberinput type="is-danger" min="16" max="99" v-model="division.max_age"></b-numberinput>
																			</b-field>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</b-tab-item>
													<!-- <b-tab-item label="Edit Headquarters">
														<p><b>Current Address:</b> {{ tournament.written_address }}</p>
														<p><b>Current City:</b> {{ tournament.city }}</p>
														<b-field label="Update The Address">
															<b-input icon-pack="fas"
																icon="street-view"
																expanded
																v-model="newTournament.written_address"></b-input>
														</b-field>
														<b-field label="Update The City">
															<b-input icon-pack="fas"
																icon="street-view"
																expanded
																v-model="newTournament.city"></b-input>
														</b-field>
														<a class="admin-button" v-on:click="updateLocation()">Update Location</a>
													</b-tab-item> -->
												</b-tabs>
											</div>
										</div>
									</b-tab-item>
									<b-tab-item label="Executive Access">
										<b-message type="is-danger" has-icon aria-close-label="Close message">
											This tab will allow you to create permissions for unlimited numbers of members to access your tournament as a "convenor".
											<br>
											They will only receive limited convenor access. They will not be able to create leagues or events. Please get them to apply with the National Office for full convenor access if they need more control.
										</b-message>
										<h3>Executive Access</h3>
										<table class="table" v-if="executive_access_players">
											<tr>
												<td><b>User</b></td>
												<td><b>Role</b></td>
												<td><b>Action</b></td>
											</tr>
											<tr :key="player.id" v-for="player in executive_access_players">
												<td>{{ player.user.first_name }} {{ player.user.last_name }}</td>
												<td>{{ player.role }}</td>
												<td v-if="tournament.convenor_user_id === authUser.id">
													<a v-on:click="makePrimaryConvenor(player)" class="button is-small is-info" v-if="tournament.convenor_user_id !== player.user.id">Make Primary Convenor</a>
													<a v-on:click="removeTheirAccess(player)" 
													v-if="!editDisabled"
													class="button is-danger is-small">Remove Their Access</a>
												</td>
											</tr>
										</table>
										<span v-if="!editDisabled">
											<h3>Add to Executive Access</h3>
											<div class="row">
												<div class="col-md-9">
													<b-field label="Search for someone" message="You can enter email, phone, username or first &amp; last name">
														<b-input v-on:input="updatePlayerSearch()" v-model="searchName"  placeholder="Search">
														</b-input>
													</b-field>
												</div>
												<div class="col-md-3">
													<b-field>
														<b-checkbox v-model="allRegionSearch" v-on:input="updatePlayerSearch()"> Include out of region users in your search</b-checkbox>    
													</b-field>
												</div>
											</div>
											<div style="max-height: 400px; overflow: scroll; -webkit-overflow-scrolling: touch;">
												<nav class="level" v-for="(user, key) in searchList" :key="key">
													<div class="level-left">
														<p class="level-item"><b>{{ user.first_name }} {{ user.last_name }}</b></p>
														<p class="level-item">{{ user.city }}</p>
														<p class="level-item">
															{{ user.email }}	
														</p>
														<p class="level-item">{{ user.gender }}</p>
								
														<p class="level-item">
															<span v-if="user.latest_waiver_signed === $latest_waiver">Signed</span>
														</p>	
													</div>
													<div class="level-right">
														<a class="button is-small" v-on:click="addToPlayersSelected(user)"><i class="fas fa-plus"></i></a>
													</div>
												</nav>
											</div>

											<div class="adding">
												<p><b>Adding:</b></p>
											</div>
											
											<section class="modal-card-body">
												<nav class="level" v-for="(user, key) in playersSelected" :key="key">
													<div class="level-left">
														<p class="level-item"><b>{{ user.first_name }} {{ user.last_name }}</b></p>
														<p class="level-item">
															<b-select v-model="user.access_title">
																<option value="Primary Convenor">
																	Primary Convenor
																</option>
																<option value="Secondary Convenor">
																	Secondary Convenor
																</option>
																<option value="Secretary">
																	Secretary
																</option>
																<option value="UIC">
																	UIC
																</option>
															</b-select>
														</p>
													</div>
													<div class="level-right">
														<a class="button is-danger is-small" v-on:click="removeFromPlayersSelected(key)"><i class="fas fa-trash"></i></a>
													</div>
												</nav>
											</section>
											<button class="admin-button" style="margin-top: 20px;" v-on:click="addToExecutiveAccess()">Add User to Executive Access</button>
										</span>
									</b-tab-item>
									<b-tab-item label="Slo-Pitch Central">
										<a href="https://slopitchcentral.com/?track=spn"><img src="https://cdn.blacktiecollab.com/slopitchcentral/slopitchcentral1.png" style="width: 100%;" alt="Slo-Pitch Central"></a>

										<h3>Features List - All For Free</h3>
										<p><b>Built specifically for Slo-Pitch National League Executives & Tournament Co-ordinators</b></p>
										<p>- Teams, Umpires or Admins can report scores, with your choice of who can or can't</p>
										<p>- Easy to use filters to find specific team schedules</p>
										<p>- Optimized schedules and standings for readability</p>
										<p>- Live updating standings and scores - no need to refresh!</p>
										<p>- Preseason, Regular Season & Playoff game types for leagues</p>
										<p>- Pre-Seed, Round Robin and Elimination game types for tournaments</p>
										<p>- Standings specific to the game type</p>
										<p>- Auto generating brackets - no matter HOW unique</p>
										<p>- Collect and track payment from teams</p>
										<p>- Frontpage website and ability to connect your domain</p>
										<p>- Connect to Umpire Cheat Sheet and work with your UIC to schedule umpires cross-platform</p>
										<p>- Easy popup for players to save their team and save their reporting code</p>
										<p>- Downloadable PDFs and CSVs for anyone</p>

										<a href="https://slopitchcentral.com" class="admin-button">Check It Out</a>
										
									</b-tab-item>
									<b-tab-item label="Pre-Event Checklist" v-if="!editDisabled">
										<div class="row">
											<div class="col-md-6" :key="key" v-for="(group, key) in filterGroup">
												<h3>{{ key }}</h3>
												<div class="field" :key="key2" v-for="(box, key2) in group">
													<b-checkbox v-model="box.result" size="is-medium" v-on:input="eventChecklistSave($event, box)">{{ box.name }}</b-checkbox>
												</div>
											</div>
											
										</div>
										<div class="row" style="margin-top: 50px;">
											<b-field label="Notes/Personal Reminders">
												<b-input v-on:input="saveEventNotes($event)" v-model="canvasAdditionalDetailsLeft"
													maxlength="200" type="textarea"></b-input>
											</b-field>
										</div>
									</b-tab-item>
							
									<b-tab-item label="Umpires/Rulesheet" v-if="!editDisabled">
										<div class="content-area">
											<div class="row">
												<div class="col-md-6">
													<h2 style="margin-top: 0px;">Umpire Cheat Sheet</h2>
													<p>Fill out this umpire cheat sheet to help them see and make sure they can umpire according to your event.</p>
													
													<table class="table" style="width: 100%; margin-top: 20px;">
														<tr>
															<td><b>Innings</b></td>
															<td>
																<b-numberinput
																	v-model="cheatSheet.innings">
																</b-numberinput>
															</td>
														</tr>
														<tr>
															<td><b>Call last inning at __ minutes</b></td>
															<td>
																<b-numberinput
																	v-model="cheatSheet.call_last_inning_after">
																</b-numberinput>
															</td>
														</tr>
														<tr>
															<td><b>Minimum players on field</b></td>
															<td>
																<b-numberinput
																	v-model="cheatSheet.minimum_players">
																</b-numberinput>
															</td>
														</tr>
														<tr>
															<td><b>HR Rule</b></td>
															<td>
																<b-input
																	v-model="cheatSheet.hr_rule">
																</b-input>
															</td>
														</tr>
														<tr>
															<td><b>Anticipation</b></td>
															<td>
																<b-switch :true-value="1" :false-value="0" v-model="cheatSheet.anticipation"></b-switch>
															</td>
														</tr>
														<tr>
															<td><b>Ties</b></td>
															<td>
																<b-switch :true-value="1" :false-value="0" v-model="cheatSheet.ties"></b-switch>
															</td>
														</tr>
														<tr>
															<td><b>Courtesy Runners</b></td>
															<td>
																<b-switch :true-value="1" :false-value="0"  v-model="cheatSheet.courtesy_runners"></b-switch>
															</td>
														</tr>
														<tr>
															<td><b>Max runs per inning</b></td>
															<td>
																<b-input
																	v-model="cheatSheet.max_runs_per_inning">
																</b-input>
															</td>
														</tr>
														<tr>
															<td><b>HR Rule</b></td>
															<td>
																<b-input
																	v-model="cheatSheet.hr_rule">
																</b-input>
															</td>
														</tr>
														<tr>
															<td><b>Mercy Rule</b></td>
															<td>
																<b-switch :true-value="1" :false-value="0"  v-model="cheatSheet.mercy_rule"></b-switch>
															</td>
														</tr>
														<tr>
															<td><b>Tag at home</b></td>
															<td>
																<b-switch :true-value="1" :false-value="0"  v-model="cheatSheet.tag_at_home"></b-switch>
															</td>
														</tr>
														<tr>
															<td><b>Throw to first from outfield</b></td>
															<td>
																<b-switch :true-value="1" :false-value="0"  v-model="cheatSheet.throw_to_first_from_outfield"></b-switch>
															</td>
														</tr>
														<tr>
															<td><b>Additional Rules</b></td>
															<td>
																<b-input type="textarea" v-model="cheatSheet.additional_rules"></b-input>
															</td>
														</tr>
													</table>
													<a v-if="savedCheatSheet === false" v-on:click="saveUmpireCheatSheet()" class="admin-button"><i class="fas fa-save"></i> Create Umpire Cheat Sheet</a>
													<a v-if="savedCheatSheet === true" v-on:click="saveUmpireCheatSheet()" class="admin-button"><i class="fas fa-save"></i> Update Umpire Cheat Sheet</a>
													
												</div>
												<div class="col-md-6">
													<div class="padding" v-if="savedCheatSheet">
														<h2 style="margin-top: 0px;">Umpires Hired</h2>
													
														<p>Add your umpires to this list via their email address to add them to the tournament. For security reasons, their email has to be a complete match.</p>

														<b-input style="margin-top: 10px;" placeholder="Search for a carded umpire by email"
															type="search"
															icon="magnify"
															icon-clickable
															v-model="umpSearch"
															v-on:input="umpireEmailSearch($event)"
														>
														</b-input>
														<div style="text-align: center; margin-top: 10px;">
															<p class="note" style="color: red;" v-if="umpireFound === null && umpSearch !== null">Umpire Not Found</p>
															<p class="note" style="color: green;" v-if="umpireFound && umpSearch !== null">Umpire Found!!</p>

															<a v-on:click="addUmpireToTournament()" v-if="umpireFound && umpSearch !== null" class="admin-button"><i class="fas fa-plus"></i> Add Umpire to Tournament</a>
														</div>
														<div>
															<h2>Umpires Added</h2>
															<table class="table">
																<tr :key="user.id" v-for="user in addedToCheatSheet">
																	<td>{{ user.user.email }}</td>
																	<td><a style="margin-left: 10px;" class="button is-small is-danger" v-on:click="removeUmpire(user)">Remove</a></td>
																</tr>
															</table>
														</div>
													</div>
												</div>
											</div>
										</div>
									</b-tab-item>
									
									<b-tab-item label="Import Previous Tournament Teams" v-if="previous_registered_teams.length > 0">
										<a class="admin-button" v-on:click="addTeamsToNewTournament()">Add {{ selectedPreviousTeams.length }} Teams to {{ moment().format('YYYY') }}</a>
										<b-table
										 	style="margin-top: 30px;"
											:data="previous_registered_teams"
											ref="table"
											checkable
											:checked-rows.sync="selectedPreviousTeams"
										>
											<b-table-column field="color" label="Color">
												<template v-slot="props">
													<div class="color-box" :style="'background: ' + props.row.team.color_1">
														
													</div>
												</template>
											</b-table-column>
											<b-table-column
												field="team.name"
												label="Name">
												<template v-slot="props">
													{{ props.row.team.name }}
												</template>
											</b-table-column>
											<b-table-column
												field="primary_coach.first_name"
												label="Coach Name">
												<template v-slot="props">
													{{ props.row.team.primary_coach.first_name }} {{ props.row.team.primary_coach.last_name }}
												</template>
											</b-table-column>
											<b-table-column
												label="Gender">
												<template v-slot="props">
													<b-select v-model="props.row.league_gender_id">
														<option value="mens" v-if="totalDivisions.mens.length > 0">Mens</option>
														<option value="ladies" v-if="totalDivisions.ladies.length > 0">Ladies</option>
														<option value="coed" v-if="totalDivisions.coed.length > 0">Coed</option>
													</b-select>
												</template>
											</b-table-column>
											<b-table-column
												label="Division">
												<template v-slot="props">
													<b-select v-model="props.row.league_division_id">
														
														<option :value="division" :key="division.id" v-for="division in totalDivisions[props.row.league_gender_id]">{{ division.rank_id }}</option>
													</b-select>
												</template>
											</b-table-column>
										</b-table>
									</b-tab-item>
								</b-tabs>
							</div>
						</div>
					</div>
                
                </div>
				
            </div>
            
		</div>
		
	</div>
</template>

<script>

import Header from '../components/PrimaryHeader.vue'
import Footer from '../components/PrimaryFooter.vue'
import LeagueTournamentPreviewCard from '../../components/LeagueTournamentPreviewCard.vue'
import RegisteredTeams from '../../../components/ViewEvent/RegisteredTeams.vue'
import axios from 'axios'
import moment from 'moment'
export default {
	data() {
		return {
			selectedPreviousTeams: [],
			selectedRegisteredTeams: [],
			page: null,
			changeTeamName: false,
			tournament: null,
			columnTemplate: [
				{ title: 'ID', field: 'id', visible: false, searchable: false },
				{ title: 'Team Name', field: 'team_name', visible: true, searchable: false },
				{ title: 'Role', field: 'role', visible: true, searchable: false },
				{ title: 'Rank', field: 'level', visible: true, searchable: false },
				{ title: 'First Name', field: 'first_name', visible: false, searchable: false },
				{ title: 'Last Name', field: 'last_name', visible: false, searchable: false },
				{ title: 'Gender', field: 'gender', visible: false, searchable: false },
				{ title: 'Date of Birth', field: 'date_of_birth', visible: true, searchable: false },
				{ title: 'Email', field: 'email', visible: false, searchable: false },
				{ title: 'Street Address', field: 'street_address', visible: true, searchable: false },
				{ title: 'City', field: 'city', visible: true, searchable: false },
				{ title: 'Province', field: 'province', visible: false, searchable: false },
				{ title: 'Postal Code', field: 'postal_code', visible: false, searchable: false },
				{ title: 'Phone', field: 'phone', visible: false, searchable: false },
				{ title: 'Waiver', field: 'waiver', visible: false, searchable: false },
			],
			previous: null,
			playersSelected: [],
			searchList: [],
			teamLogo: null,
			en: true,
			upcoming: null,
			executive_access_players: null,
			searchForTeam: null,
			addTeamToEventSwitch: false,
			umpSearch: null,
			registered_teams: [],
			content_width: false,
			previous_tournament: null,
			mountCheckboxes: null,
			changeDivisionSwitch: false,
			canvasAdditionalDetailsLeft: null,
			savedCheatSheet: false,
			umpireFound: null,
			addedToCheatSheet: null,
			searchResults: null,
			divisions: null,
			selectedTeam: null,
			selectRegion: false,
			authUser: null,
			
			newTournament: {
				headquarter_location: null,
				headquarter_google_address: null,
			},
			addNewDivisionSwitch: false,
			addDivisionDetails: {
				gender: null,
				rank_id: null,
				min_number_of_teams: 4,
				max_number_of_teams: 8,
				min_age: 18,
				max_age: 99
			},
			timer: null,
			previous_registered_teams:[],
			registerForTournament: {
                team: null,
                gender: null,
                division: null
			},
			changeDivisionDetails: {
				team: null,
				gender: null,
				division: null
			},
			editDisabled: false,
			searchName: null,
			allRegionSearch: false,
			cheatSheet: {
				innings: 7,
				call_last_inning_after: 75,
				minimum_players: 10,
				hr_rule: '1',
				anticipation: true,
				ties: true,
				courtesy_runners: true,
				max_runs_per_inning: 6,
				mercy_rule: true,
				tag_at_home: true,
				throw_to_first_from_outfield: true,
				additional_rules: null
			},
			registeredTeams: [],
			editTournament: null
		};
	},
	components: {
		Header,
		Footer,
		LeagueTournamentPreviewCard,
		RegisteredTeams
	},
	watch: {
		
	},
	mounted: function() {
        // grab all the dashboard information now
		var vm = this
		this.page = this.$route.params.page
		var slug = this.$route.params.slug

		axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.authUser = results.data.user

				axios.get('/api/tournaments/tournament-executive-access/' + slug).then(results => {
					if(results.data.success === true) {
						vm.executive_access_players = results.data.executive_access
						
						console.log('executive_access_players', vm.executive_access_players)

						var check_for_user = _.filter(vm.executive_access_players, function(player) {
								return player.user_id === vm.authUser.id
							})
							
							if(check_for_user.length === 0) {
								this.$router.push({ name: 'Dashboard' })
								this.$buefy.snackbar.open({
									message: 'You do not have permission to access this.',
									type: 'is-success' // is-danger
								})
							}
					}
				})
            }
		})

		axios.post('/api/tournaments/review-checklist').then(results => {
			if(results.data.success === true) {
				vm.mountCheckboxes = results.data.checklist
			}
		})

		if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }
		

		axios.get('/api/tournaments/divisions').then(results => {
			vm.divisions = results.data.divisions
		})

		axios.get('/auth/check').then(results => {
            if(results.data.type === "success") {
                var user = results.data.user
				vm.user = user
				
				axios.get(`/api/tournaments/get-tournament/${slug}`).then(results => {
					if(results.data.success === true) {				
						vm.tournament = results.data.tournament
						vm.editTournament = results.data.tournament
						
						if(vm.editTournament.season_id != parseInt(moment().format('YYYY'))) {
							
							vm.editDisabled = true
						}
			
						if(vm.editTournament.event_start_date) {
							vm.editTournament.event_start_date = new Date(vm.editTournament.event_start_date)
						}
						if(vm.editTournament.event_end_date) {
							vm.editTournament.event_end_date = new Date(vm.editTournament.event_end_date)
						}
						if(vm.editTournament.registration_start_date) {
							vm.editTournament.registration_start_date = new Date(vm.editTournament.registration_start_date)
						}
				
						if(vm.editTournament.registration_end_date) {
							vm.editTournament.registration_end_date = new Date(vm.editTournament.registration_end_date)
						}

						if(vm.editTournament.roster_lock_date) {
							vm.editTournament.roster_lock_date = new Date(vm.editTournament.roster_lock_date)
						}

						// if(vm.tournament.convenor_user_id !== user.id) {
						// 	this.$router.push({ name: 'Dashboard' })
						// 	this.$buefy.snackbar.open({
						// 		message: 'You do not have permission to access this.',
						// 		type: 'is-success' // is-danger
						// 	})
						// }
						
						if(vm.tournament.previous_tournament_id) {
							axios.get(`/api/tournaments/get-tournament/${vm.tournament.previous_tournament_id}`).then(results => {
								if(results.data.success === true) {			
								
									vm.previous_tournament = results.data.tournament

									axios.get('/api/tournaments/get-registered-teams/' + vm.previous_tournament.slug).then(results => {
										if(results.data.success === true) {
											vm.previous_registered_teams = results.data.registrants
										}
									})
								}
							})
						}
					

						axios.post('/api/tournaments/review-checklist', {
							event_id: vm.tournament.id,
							event_type: 'tournament',	
						}).then(results => {
							if(results.data.success === true) {

								vm.mountCheckboxes = results.data.checklist
								if(results.data.notes) {
									vm.canvasAdditionalDetailsLeft = results.data.notes.result
								}
							}
						})

						axios.get('/api/umpires/cheat-sheet/' + vm.tournament.id + '/tournament').then(results => {
							if(results.data.success === true) {
								if(results.data.cheat_sheet) {
									vm.cheatSheet = results.data.cheat_sheet
									vm.savedCheatSheet = true
								
									axios.get('/api/umpires/added-to-cheat-sheet/' + vm.cheatSheet.id + '/tournament').then(results => {
										if(results.data.success === true) {
											vm.addedToCheatSheet = results.data.added_to_cheat_sheet
											
										}
									})
								}
							}
						})
					}
				})
            }
        })

		axios.get(`/api/tournaments/get-registered-teams-with-rosters/${slug}`).then(results => {
			if(results.data.success === true) {
				
				vm.registeredTeams = results.data.registrants
			}
		})
	},
	computed: {
		computedSelectedRegisteredTeams: function() {
            var vm = this
			
			var teams = this.selectedRegisteredTeams
			var array = []
			var i = 0
			
			for(i; i < teams.length; i++) {
				var team = teams[i]
				
				var s = 0

				for(s; s < team.roster.roster_players.length; s++) {
					var roster = team.roster.roster_players[s]

					var role = 'Player'

					if(roster.core_roster_player.role_on_team === 1) {
						role = 'Coach'

						if(roster.user_id === team.team.primary_coach_id) {
							role = "Primary Coach"
						}
					}
					if(roster.core_roster_player.role_on_team === 2) {
						role = 'Team Contact'
					}

					array.push({
						id: team.id,
						team_name: team.team.name,
						first_name: roster.user.first_name,
						last_name: roster.user.last_name,
						gender: roster.user.gender,
						date_of_birth: roster.user.date_of_birth,
						email: roster.user.email,
						street_address: roster.user.street_address,
						city: roster.user.city,
						province: roster.user.province,
						postal_code: roster.user.postal_code,
						phone: roster.user.phone,
						level: roster.user.current_rank_id,
						waiver: (roster.user.latest_waiver_signed === this.$latest_waiver ? 'Yes' : 'No'),
						role: role
					})
				}	
			}

			return array
        },

		spnRegistration: function() {
            // grab the registrations of insurance from the team
            // cross reference the division
            var vm = this

            var team = vm.selectedTeam

            var selected_gender = vm.registerForTournament.gender
            
            if(team) {
                
                var insurance = team.insurance
                
                var find_insurance = _.find(insurance, function(paper) {
                    if(paper.gender === selected_gender && paper.season == moment().format('YYYY')) {
                        return true
                    }
                })

                if(find_insurance) {
					
                    vm.currentInsurance = find_insurance
                    vm.triggerFinalize = true
                    return true
                }
                vm.triggerFinalize = false
                return false
            } else {
                vm.triggerFinalize = false
                return false
            }
        },
		totalDivisions: function() {
            var vm = this
            var mens = vm.filterDivision(vm.tournament.divisions, 'mens')
            var ladies = vm.filterDivision(vm.tournament.divisions, 'ladies')
            var coed = vm.filterDivision(vm.tournament.divisions, 'coed')

            var count = 0
            if(mens.length > 0) {
                count = count + 1
            } 
            if(ladies.length > 0) {
                count = count + 1
            }
            if(coed.length > 0) {
                count = count + 1
            }

            return {
                mens: mens,
                ladies: ladies,
                coed: coed,
                count: count
            }
        },
		filterGroup: function() {
			var vm = this
			var grouped = _.groupBy(vm.mountCheckboxes, 'group')

			return grouped
		}
	},
	methods: {
		moment: function(date, format = null) {
            return moment(date, format)
        },
		grabRegisteredTeams: function() {
            var vm = this
            axios.get(`/api/tournaments/get-registered-teams-with-rosters/${vm.eventId}`).then(results => {
				if(results.data.success === true) {
					vm.registeredTeams = results.data.registrants
				}
			})
            
        },
		addTeamsToNewTournament: function() {
			var vm = this
			var i = 0

			var failed = false
			var previous_teams = []

			for(i; i < vm.selectedPreviousTeams.length; i++) {
				var previous_team = vm.selectedPreviousTeams[i]

				if(previous_team.tournament_gender_id === null) {
					failed = true
				}
				if(previous_team.tournament_division_id === null) {
					failed = true
				}

				previous_teams.push({
					id: previous_team.id,
					team_id: previous_team.team_id,
					tournament_division_id: previous_team.tournament_division_id,
					tournament_gender_id: previous_team.tournament_gender_id,
					team_roster_id: previous_team.team_roster_id
				})

				if(failed === false) {
					axios.post('/api/tournaments/add-teams-from-previous-season', {
						previous_teams: previous_teams,
						tournament_id: vm.tournament.id
					}).then(results => {
						if(results.data.success === true) {
							vm.addTeamsToNewListSwitch = false
							vm.selectedPreviousTeams = []
							vm.refreshLeague()
							this.$buefy.snackbar.open({
								message: 'Added teams to the new tournament. Refresh to see the changes.',
								type: 'is-success' // is-danger
							})	
						} else {
							this.$buefy.snackbar.open({
								message: 'There was a problem importing your teams. Please check all your division and genders for missing information.',
								type: 'is-danger' // is-danger
							})
						}
					}).catch(err => {
						this.$buefy.snackbar.open({
							message: 'There was a problem importing your teams. Please check all your division and genders for missing information.',
							type: 'is-danger' // is-danger
						})
					})
				}
			}
		},
		updateTeams: function() {
			var vm = this

			axios.get(`/api/tournaments/get-registered-teams/${vm.tournament.slug}`).then(results => {
				if(results.data.success === true) {
					vm.registeredTeams = results.data.registrants
				}
			})
		},
		filterDivision: function(divisions, filter) {
            var vm = this

            var filtered_divisions = _.filter(divisions, function(division) {
                return division.gender_slug === filter
            })

            return filtered_divisions
        },
		switchWidth: function($event) {
			this.content_width = !$event
		},
		saveUmpireCheatSheet: function() {
			var vm = this

			axios.post('/api/umpires/save-cheat-sheet', {
				cheat_sheet: vm.cheatSheet,
				event_id: vm.tournament.id,
				type: 'tournament'
			}).then(results => {
				if(results.data.success === true) {
					vm.savedCheatSheet = true

				}
			})
		},
		umpireEmailSearch: function(text) {
			var vm = this
			axios.post('/api/umpires/search-for-umpire', {
				search: text
			}).then(results => {
				if(results.data.success === true) {
					
					vm.umpireFound = results.data.umpire
				}
			})
		},
		addUmpireToTournament: function() {

			var vm = this
			axios.post('/api/umpires/add-to-cheat-sheet', {
				umpire_found: vm.umpireFound,
				cheat_sheet: vm.cheatSheet.id
			}).then(results => {
				if(results.data.success === true) {
					vm.addedToCheatSheet = results.data.added_to_cheat_sheet

					this.$buefy.snackbar.open({
                        message: 'Added user to the cheat sheet',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				} else {
					this.$buefy.snackbar.open({
                        message: 'User was not added to the cheat sheet',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
				}
			})
		},
		eventChecklistSave: function($event, box) {

			var vm = this
			axios.post('/api/tournaments/save-checklist', {
				box: box,
				event_id: vm.tournament.id,
				event_type: 'tournament',
				result: $event
			}).then(results => {
				if(results.data.success === true) {
					this.$buefy.snackbar.open({
                        message: 'Save Event Checklist',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				}
			})
		},
		saveEventNotes: function($event) {

			var vm = this

			axios.post('/api/tournaments/save-checklist-notes', {
				event_id: vm.tournament.id,
				event_type: 'tournament',
				result: $event
			}).then(results => {
				if(results.data.success === true) {
					this.$buefy.snackbar.open({
                        message: 'Save checklist notes',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				}
			})
		},
		removeUmpire: function(user) {
			var vm = this
			axios.post('/api/umpires/remove-from-cheat-sheet', {
				user_id: user.user.id,
				cheat_sheet: vm.cheatSheet.id
			}).then(results => {
				if(results.data.success === true) {
					vm.addedToCheatSheet = results.data.added_to_cheat_sheet
					this.$buefy.snackbar.open({
                        message: 'Removed user from the cheat sheet',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				} else {
					this.$buefy.snackbar.open({
                        message: 'User was not deleted to the cheat sheet',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
				}
			})
		},
		addTeamToEvent: function() {
			var vm = this

			vm.addTeamToEventSwitch = true
		},
		searchForTeamFunction: function($event = null) {
			var vm = this


            if(this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {
				if($event) {
					axios.post('/api/tournaments/search-for-team', {
						tournament_id: vm.tournament.id,
						search: $event,
						region_on: vm.selectRegion,
						region_id: vm.tournament.region_id
					}).then(results => {
						if(results.data.success === true) {
							vm.searchResults = results.data.teams
						}
					})
				} else {
					axios.post('/api/tournaments/search-for-team', {
						tournament_id: vm.tournament.id,
						search: vm.searchForTeam,
						region_on: vm.selectRegion,
						region_id: vm.tournament.region_id
					}).then(results => {
						if(results.data.success === true) {

							vm.searchResults = results.data.teams
						}
					})
				}
			}, 500)
		},
		checkIfPreviousDate: function(tournament) {
			
			var start_date = moment(tournament.event_start_date)

			var is_after = start_date.isAfter(moment())
			
			// var now = moment().format('YYYY-MM-DD')
			// var tournament_start_date = moment(tournament.event_start_date).format('YYYY-MM-DD')
			// console.log('isAfter', now.isAfter(tournament.event_start_date))
			return is_after
		},
		registerTeamToEvent: function() {
			var vm = this
			axios.post('/api/tournaments/add-team-to-event', {
				registration: vm.registerForTournament,
				insurance: vm.currentInsurance,
				correct_insurance: vm.spnRegistration,
				tournament_name: vm.tournament.name
			}).then(results => {
				if(results.data.success === true) {
					vm.updateTeams()
					vm.addTeamToEventSwitch = false
					this.$buefy.snackbar.open({
                        message: 'Team added successfully to your event.',
                        type: 'is-success' // is-danger
                    })
				}
			})
		},
		removeTeam: function(team) {
			
			confirm("Are you sure you want to remove this team from your event?")

			axios.post('/api/tournaments/remove-team-from-event', {
				team_id: team.id
			}).then(results => {
				this.$buefy.snackbar.open({
					message: 'Removed user from the event',
					type: 'is-success', // is-danger
					duration: 4000,
					queue: false
				})
			})
		},
		changeDivisionFunction: function(team) {
			
			var vm = this
			vm.changeDivisionDetails.team = team
			vm.changeDivisionSwitch = true
		},
		moveTeamToNewDivision: function(team) {
			var vm = this

			var data = {
				details: {
					team: {
						id: vm.changeDivisionDetails.team.id,
						insurance_id: vm.changeDivisionDetails.team.insurance_id
					},
					division: {
						id: vm.changeDivisionDetails.division.id
					},
					gender: vm.changeDivisionDetails.gender
				}
			}
			
			axios.post('/api/tournaments/change-team-to-new-division', data).then(results => {
				if(results.data.success === true) {
					vm.updateTeams()
					vm.changeDivisionSwitch = false
					this.$buefy.snackbar.open({
                        message: 'Team moved to new division successfully.',
                        type: 'is-success' // is-danger
                    })
				}
			})
		},
		moment: function(date, format = null) {
			return moment(date, format)
		},
		updateTournamentInfo: function() {
			var vm = this
			
			axios.post('/api/tournaments/update-tournament', {
				tournament: vm.editTournament
			}).then(results => {
				if(results.data.success === true) {
					
					this.$buefy.snackbar.open({
						message: 'Tournament Info Has Been Updated',
						type: 'is-success' // is-danger
					})
					vm.tournament = vm.editTournament
				} else {
					this.$buefy.snackbar.open({
						message: 'Something went wrong when saving',
						type: 'is-danger' // is-danger
					})
				}
			})
		},
		updateAddressTab: function() {
			var vm = this
		
		},
		fillInAddress: function() {
			var vm = this
			var place = this.autocompleteAddress.getPlace();
			var fields = this.autocompleteAddress.getFields()
			this.newTournament.headquarter_location = place.formatted_address

			// find components
			var address_components = place.address_components

			var postal_code = _.find(address_components, function(address) {
				if(address.types.includes('postal_code')) {
					return true
				}
			})

			var province = _.find(address_components, function(address) {
				if(address.types.includes('administrative_area_level_1')) {
					return true
				}
			})

			if(province.short_name === 'NS' || province.short_name === 'PE' || province.short_name === 'NB' ) {
				var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'MT') {
						return true
					}
				})

				
				vm.newTournamentRegion = region_data
			} else if (province.short_name === 'NU') {
                
                var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'MB') {
						return true
					}
				})
                
				vm.newTournamentRegion = region_data
            } else {
				var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === province.short_name) {
						return true
					}
				})
				
				vm.newTournamentRegion = region_data
			}

			var city = _.find(address_components, function(address) {
				if(address.types.includes('locality')) {
					return true
				} else {
                    if(address.types.includes('sublocality')) {
                        return true
                    }
                }
			})

			var street_number = _.find(address_components, function(address) {
				if(address.types.includes('street_number')) {
					return true
				}
			})

			var route = _.find(address_components, function(address) {
				if(address.types.includes('route')) {
					return true
				}
			})

			var street_number_long_name = null

			if(street_number) {
				street_number_long_name = street_number.long_name
			}

			var route_long_name = null

			if(route) {
				route_long_name = route.long_name
			}


			vm.newTournament.headquarter_google_address = {
				written_address: place.formatted_address,
				street_address: street_number_long_name + ' ' + route_long_name,
				city: city.long_name,
				province: province.short_name,
				postal_code: postal_code.long_name,
				latitude:  place.geometry.location.lat(),
				longitude: place.geometry.location.lng()
			}
		},
		updateLocation: function() {
			var vm = this
			axios.post('/api/tournaments/update-tournament-location', {
				tournament: vm.tournament.id,
				headquarters: vm.newTournament
			}).then(results => {
				if(results.data.success === true) {
					this.$buefy.snackbar.open({
						message: 'Updated tournament location.',
						type: 'is-success', // is-danger
						duration: 4000,
					})
				}
			})
		},
		addNewDivision: function() {
			var vm = this

			axios.post('/api/tournaments/add-new-division', {
				tournament: vm.tournament.id,
				division: vm.addDivisionDetails
			}).then(results => {
				if(results.data.success === true) {
					vm.addNewDivisionSwitch = false
					this.$buefy.snackbar.open({
						message: 'Added new division',
						type: 'is-success', // is-danger
						duration: 4000,
					})
				} else {
					this.$buefy.snackbar.open({
						message: 'Division not added successfully. Are you sure you do not already have that division?',
						type: 'is-danger', // is-danger
						duration: 4000,
					})
				}
			})
		},
		teamCount: function(division) {
			var vm = this

			var teams = _.filter(vm.registeredTeams, function(team) {
				return team.league_division_id === division.id
			})

			return teams.length
		},
		updateDivision: function(division) {
			var vm = this
			axios.post('/api/tournaments/update-division', {
				division: division
			}).then(results => {
				if(results.data.success === true) {
					this.$buefy.snackbar.open({
						message: 'Updated the division',
						type: 'is-success' // is-danger
					})
				} else {
					this.$buefy.snackbar.open({
						message: 'Something went wrong',
						type: 'is-danger' // is-danger
					})
				}
			})
		},
		deleteDivision: function(division) {
			var r = confirm('Are you sure you want to delete this division?')

			if(r === true) {
				axios.post('/api/tournaments/delete-division', {
					division: division.id
				}).then(results => {
					if(results.data.success === true) {
						this.$buefy.snackbar.open({
							message: 'Deleted the division',
							type: 'is-success' // is-danger
						})
					} else {
						this.$buefy.snackbar.open({
							message: 'Something went wrong',
							type: 'is-danger' // is-danger
						})
					}
				})
			}
			
		},
		grabExecutiveAccessList: function() {
			var vm = this

			axios.get('/api/tournaments/tournaments-executive-access/' + vm.tournament.id).then(results => {
				if(results.data.success === true) {
					vm.executive_access_players = results.data.executive_access
				}
			})
		},
		refreshTournament: function() {
			var vm = this

			axios.get(`/api/tournaments/get-tournament/${vm.tournament.slug}`).then(results => {
				if(results.data.success === true) {				
					vm.tournament = results.data.tournament
					vm.editTournament = results.data.tournament
		
					if(vm.editTournament.event_start_date) {
						vm.editTournament.event_start_date = new Date(vm.editTournament.event_start_date)
					}
					if(vm.editTournament.event_end_date) {
						vm.editTournament.event_end_date = new Date(vm.editTournament.event_end_date)
					}
					if(vm.editTournament.registration_start_date) {
						vm.editTournament.registration_start_date = new Date(vm.editTournament.registration_start_date)
					}
			
					if(vm.editTournament.registration_end_date) {
						vm.editTournament.registration_end_date = new Date(vm.editTournament.registration_end_date)
					}

					if(vm.editTournament.roster_lock_date) {
						vm.editTournament.roster_lock_date = new Date(vm.editTournament.roster_lock_date)
					}
					
					if(vm.tournament.previous_tournament_id) {
						axios.get(`/api/tournaments/get-tournament/${vm.tournament.previous_tournament_id}`).then(results => {
							if(results.data.success === true) {			
							
								vm.previous_tournament = results.data.tournament

								axios.get('/api/tournaments/get-registered-teams/' + vm.previous_tournament.slug).then(results => {
									if(results.data.success === true) {
										vm.previous_registered_teams = results.data.registrants
									}
								})
							}
						})
					}
				

					axios.post('/api/tournaments/review-checklist', {
						event_id: vm.tournament.id,
						event_type: 'tournament',	
					}).then(results => {
						if(results.data.success === true) {

							vm.mountCheckboxes = results.data.checklist
							if(results.data.notes) {
								vm.canvasAdditionalDetailsLeft = results.data.notes.result
							}
						}
					})

					axios.get('/api/umpires/cheat-sheet/' + vm.tournament.id + '/tournament').then(results => {
						if(results.data.success === true) {
							if(results.data.cheat_sheet) {
								vm.cheatSheet = results.data.cheat_sheet
								vm.savedCheatSheet = true
							
								axios.get('/api/umpires/added-to-cheat-sheet/' + vm.cheatSheet.id + '/tournament').then(results => {
									if(results.data.success === true) {
										vm.addedToCheatSheet = results.data.added_to_cheat_sheet
										
									}
								})
							}
						}
					})
				}
			})
		},
		addToExecutiveAccess: function() {
			var vm = this

			axios.post('/api/tournaments/add-to-tournament-executive-access', {
				tournament_id: vm.tournament.id,
				users: vm.playersSelected,
			}).then(results => {
				if(results.data.success === true) {
					vm.playersSelected = []
					vm.refreshTournament()
					this.$buefy.snackbar.open({
						message: 'Added members to the executive!',
						type: 'is-success', // is-danger
						duration: 4000,
						queue: false
					})
				}
			})
		},
		makePrimaryConvenor: function(player) {
			var vm = this
			
			axios.post('/api/tournaments/make-primary-convenor', {
				player: player,
				tournament: vm.tournament.id
			}).then(results => {
				if(results.data.success === true) {
					vm.refreshTournament()
					this.$buefy.snackbar.open({
                        message: 'Convenor marked as primary executive!',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				} else {

				}
			})
		},
		removeTheirAccess: function(player) {
			var vm = this

			axios.post('/api/tournaments/remove-executive-access', {
				player: player,
				tournament: vm.tournament.id
			}).then(results => {
				if(results.data.success === true) {
					vm.refreshTournament()
					this.$buefy.snackbar.open({
                        message: 'Removed executive access successfully!',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				} else {

				}
			})
		},
		updatePlayerSearch: function($event) {
            var vm = this

			if(this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {

				axios.post(`/api/users/search/players`, {
					search_name: vm.searchName,
					region_id: vm.tournament.region_id,
					all_regions: vm.allRegionSearch
				}).then(results => {
					if(results.data.success === true) {
						vm.searchList = results.data.users
					}
				})
			}, 1000)
		},
		addToPlayersSelected: function(user) {
            this.playersSelected.push(user)
        },
        removeFromPlayersSelected: function(key) {
            this.playersSelected.splice(key, 1)
        },
		sendWaiversToTournament: function() {
			var vm = this

			var r = confirm('Are you sure you want to send an email to all unsigned waiver players?')

			if(r === true) {
				axios.post('/api/tournaments/send-waivers', {
					tournament_id: vm.tournament.id
				}).then(results => {
					if(results.data.success === true) {
						vm.refreshTournament()
						this.$buefy.snackbar.open({
							message: 'You have sent your waiver reminder email to all unsigned players.',
							type: 'is-success', // is-danger
							duration: 4000,
						})
					}
				})
			}
		}
	}
}
</script>

<style lang="scss">
    
</style>
