import Vue from 'vue'
import VueApp from './App.vue'
// import { Table, Datepicker, Datetimepicker, Timepicker, Field, Input, Dropdown, Steps, Tooltip, Modal, Carousel, Tabs, Radio, Button, Icon, Upload, Menu, Slider, Checkbox, Numberinput, Pagination, Select, Tag } from 'buefy'

import VueSignaturePad from 'vue-signature-pad';

import VueTour from 'vue-tour'

import Buefy from 'buefy'
import VueExcelXlsx from "vue-excel-xlsx";

import Vue2TouchEvents from 'vue2-touch-events'

import 'buefy/dist/buefy.css'
import 'vue-swatches/dist/vue-swatches.css'
import VueKonva from 'vue-konva'

import CKEditor from '@ckeditor/ckeditor5-vue';
import router from './router'

import VueMeta from 'vue-meta'
import VueExcelEditor from 'vue-excel-editor'
import VueApexCharts from 'vue-apexcharts'

import Accordion from './pages/components/Accordion.vue' // adjust the path according to your project structure

// Vue.compon

require('vue-tour/dist/vue-tour.css')

 

// Export a factory function for creating a fresh app
export function createVueApp() {

	const app = new Vue({
        router,
		render: h => h(VueApp),
	})

	Vue.prototype.$latest_waiver = 21
	
	Vue.use(VueMeta, {
		// optional pluginOptions
		refreshOnceOnNavigation: true
	})
	Vue.use(VueSignaturePad);

	// Vue.use(Checkbox)
	// Vue.use(Numberinput)
	// Vue.use(Table)
	// Vue.use(Datepicker)
	// Vue.use(Field)
	// Vue.use(Input)
	// Vue.use(Dropdown)
	// Vue.use(Steps)
	// Vue.use(Tooltip)
	// Vue.use(Modal)
	// Vue.use(Carousel)
	// Vue.use(Tabs)
	// Vue.use(Datetimepicker)
	// Vue.use(Radio)
	// Vue.use(Button)
	// Vue.use(Icon)
	// Vue.use(Upload)
	// Vue.use(Menu)
	// Vue.use(Slider)
	Vue.use(VueKonva)
	Vue.use(CKEditor)
	Vue.use(VueTour)
	// Vue.use(Pagination)
	// Vue.use(Select)
	// Vue.use(Tag)
	// Vue.use(Timepicker)
	Vue.use(VueApexCharts)
	Vue.use(VueSignaturePad);
	Vue.use(Buefy)
	Vue.use(Vue2TouchEvents)
	Vue.use(VueExcelXlsx)
	Vue.use(VueExcelEditor)
	Vue.component('Accordion', Accordion)


	return { app, router }
}
