<template>
	<div>
		<Header></Header>
		<div class="content-area form-content-area">
			<h1 v-if="en">System Forms - Bilingual</h1>
			<h1 v-else>FORMULAIRES DU SYSTÈME-BILINGUE</h1>
			
			<div v-if="forms && !selectedForm">
				
				<b-table
					:data="forms">
					<b-table-column label="Form Name English">
						<template v-slot="props">
							{{ props.row.name }}
						</template>
					</b-table-column>
					<b-table-column label="Form Name French">
						<template v-slot="props">
							{{ props.row.fr_name }}
						</template>
					</b-table-column>
					<!-- <b-table-column label="Last Updated">
						<template v-slot="props">
							{{ moment(props.row.updated_at).format('YYYY-MM-DD') }}
						</template>
					</b-table-column> -->
					<!-- <b-table-column label="">
						<template v-slot="props">
							<a v-on:click="viewForm(props.row)" class="button">
								Fill Out Online
							</a>
							
						</template>
					</b-table-column> -->
					<b-table-column label="">
						<template v-slot="props">
							<a :href="props.row.form_url" class="button is-info">
								Download PDF (EN)
							</a>
							<a :href="props.row.form_fr_url" class="button is-info">
								TÉLÉCHARGEZ PDF (FR)
							</a>
							
						</template>
					</b-table-column>

					
				</b-table>
			</div>
			
			<div v-if="selectedForm">
				<div style="margin: 10px 0;" class="button" v-on:click="viewForm(null)">Back To Forms</div>
				<Form :form-id="selectedForm.slug"></Form>
			</div>

			
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import Form from '../components/Form.vue'
export default {
	metaInfo() {
        return { 
            title: "Forms - Slo-Pitch National",
            meta: [
                { name: 'description', content:  'Slo-Pitch National forms are listed here, like Incident Report, Request an Insurance Certificate and more.' },
				{ property: 'og:title', content: "Forms - Slo-Pitch National"},
				{ property: 'og:url', content:  'https://www.slo-pitch.com/forms' },
				{ property: 'og:site_name', content: 'Slo-Pitch National'},
				{ property: 'og:image', content: 'https://slo-pitch.com/uploads/facebook/forms.png' },
                { property: 'og:type', content: 'website' },    
                { name: 'robots', content: 'index,follow' } 
            ]
        }
    },
	data() {
		return {
			forms: null,
			selectedForm: null
		};
	},
	components: {
		Header,
		Footer,
		Form
	},
	mounted: function() {
		var vm = this
		axios.get('/api/forms/all').then(results => {
			if(results.data.success === true) {
				vm.forms = results.data.forms
			}
		})
		
	},
	computed: {
	
	},
	methods: {
		moment: function(date, format = null) {
			return moment(date, format)
		},
		viewForm: function(form) {
			var vm = this

			this.selectedForm = form
		}
	}
}
</script>

<style lang="scss">
    @import '../../styles/Forms.scss';
</style>
