<template>
	<div>
		<Header @update-lang="updateLanguage($event)"></Header>
		<div class="content-area">
			
			<div class="custom-carousel">
				<b-carousel
				
				:indicator="indicator"
				:indicator-background="indicatorBackground"
				:indicator-inside="indicatorInside"
				:indicator-mode="indicatorMode"
				:indicator-position="indicatorPosition"
				:indicator-style="indicatorStyle">
					<b-carousel-item v-for="(carousel, i) in carousels" :key="i">
						<a :href="carousel.href">
							<section :class="`hero hero-frontpage is-medium is-${carousel.color}`" :style="'background: url(' + carousel.image + ') center center;'">
								<div class="hero-body has-text-centered">
									<h1 class="title" :style="'color:' + carousel.font_color">{{ carousel.text }}</h1>
								</div>
							</section>
						</a>
					</b-carousel-item>
				</b-carousel>
			</div>
			
			<div class="frontend-content-wrapper">
				<div class="row">
					<div class="col-md-4">
						<div class="margin col-text-box">
							<img src="../../images/home/unnamed.png" alt="SPN-Events2.jpg" v-if="en === true">
							<img src="../../images/home/unnamed-fr.png" alt="SPN-Events2.jpg" v-if="en === false">
							<div class="col-text-header">
								{{ en === true ? 'Slo-Pitch National Events' : 'ÉVÉNEMENTS NATIONAUX SLO-PITCH' }}
							</div>
							<div class="col-text-info" v-if="en === true">
								From Regional Tournaments and Provincials to National Championships!
							</div>
							<div class="col-text-info" v-if="en === false">
								Des tournois régionaux et provinciaux aux championnats nationaux!
							</div>
							
							<a role="button" href="/tournament-calendar" v-if="en === true" class="admin-button"  style="margin-top: 36px;">Click for Details!</a>
							<a role="button" href="/tournament-calendar" v-if="en === false" class="admin-button"  style="margin-top: 36px;">Cliquez ici pour plus de détails!</a>
							
						</div>
					</div>
					<div class="col-md-4">
						<div class="margin col-text-box">
							<img src="../../images/home/unnamed-1.png" alt="SPN-Events2.jpg" v-if="en === true">
							<img src="../../images/home/unnamed-1-fr.png" alt="SPN-Events2.jpg" v-if="en === false">
							<div class="col-text-header">
								{{ en === true ? 'Registration' : 'Inscription' }}
							</div>
							<div class="col-text-info" v-if="en === true">
								Join the SPN4You program to gain access to leagues, tournaments, discounts and more.
							</div>
							<div class="col-text-info" v-if="en === false">
								Rejoignez le programme SPN4You pour accéder aux ligues, tournois, réductions et plus encore.
							</div>
							<a href="/login" v-if="en === true" class="admin-button" style="margin-top: 36px;">Click for Details!</a>
							<a href="/login" v-if="en === false" class="admin-button"  style="margin-top: 36px;">Cliquez ici pour plus de détails!</a>
						</div>
					</div>
					<div class="col-md-4">
						<div class="margin col-text-box">
							<img src="../../images/home/unnamed-2.png" alt="SPN-Events2.jpg" v-if="en === true">
							<img src="../../images/home/unnamed-2-fr.png" alt="SPN-Events2.jpg" v-if="en === false">
							<div class="col-text-header">
								{{ en === true ? 'Take The Umpire Exam!' : `Passez l'examen d'arbitre!` }}
							</div>
							<div class="col-text-info" v-if="en === true">
								The Umpire Exam is closed until Feb 1
							</div>
							<div class="col-text-info" v-if="en === false">
								L'examen d'arbitre est maintenant disponible! Venez répondre au quiz!
							</div>
							<a role="button"  aria-label="Take the Exam"  href="/umpires/take-the-exam" v-if="en === true" class="admin-button" style="margin-top: 35px;">Click for Details!</a>
							<a role="button"  aria-label="Take the Exam" href="/umpires/take-the-exam" v-if="en === false" class="admin-button"  style="margin-top: 36px;">Cliquez ici pour plus de détails!</a>		
						</div>
					</div>
				</div>
				<hr>
			
				<hr>
			</div>
			
			<div class="about-spn">
				<div class="frontend-content-wrapper">
					<div class="row">
						<div class="col-md-4">
							<img src="../../images/header/SPNLogo2019-White.png" alt="SPN Logo" style="max-width: 250px; margin: 0 auto; display: block;" width="100%">
						</div>
						<div class="col-md-8">
							<div class="padding padding-top-front" aria-label="Main website content" role="contentinfo" style="padding-left: 30px;">
								<h1 style="font-size: 24px; margin-top: 0px;">Slo-Pitch National, first started offering tournaments to Canada's slo-pitch community in 1989.</h1>
								<p style="color: #fff; font-size: 20px;">Slo-Pitch National offers, to member teams, a Canada-wide qualifying tournament program leading to Regional, Provincial and National championships in over 22 category and calibre levels. Slo-Pitch National, along with a dedicated team of volunteers, administer, coordinate and regulate over 950 provincial qualifying tournaments each season across Canada.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="latest-news">
				<h2 style="margin-top: 0px; padding-top: 50px; color: #fff;">Latest News</h2>
				<div class="frontend-content-wrapper">
					<b-carousel 
						:indicator="indicator"
						:indicator-background="indicatorBackground"
						:indicator-inside="indicatorInside"
						:indicator-mode="indicatorMode"
						:indicator-position="indicatorPosition"
						:indicator-style="indicatorStyle"
						:autoplay="false">
						<b-carousel-item v-for="(carousel, i) in latestNews" :key="i">
							<div class="padding" style="padding-bottom: 50px;">
								<div class="row">
									<div class="col-md-6">
										<div class="padding" >
											<section :class="`hero is-${carousel.color}`" >
												<div class="hero-body has-text-centered">
													<img v-if="carousel.image" :src="carousel.image" width="100%" alt="Carousel Image">
												</div>
											</section>
										</div>
									</div>
									<div class="col-md-6">
										<div class="padding">
											<h2 style="margin-top: 0px;">{{ carousel.name }}</h2>
											<div v-html="carousel.description">

											</div>
											
											<a aria-label="Read More about News" :href="'/news/' + carousel.slug" class="admin-button" style="background: #333;">Read More</a>
										</div>
									</div>
								</div>
							</div>
							
						</b-carousel-item>
					</b-carousel>
				</div>
			</div>
			<div class="frontend-content-wrapper">
				
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>

import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'

import image0 from '../../images/home/shopify1.png'
import image1 from '../../images/home/nameinthegame.png'
// import image1 from '../../images/home/1-2.png'
import image2 from '../../images/home/1.png'
import image3 from '../../images/home/1-1.png'
import image4 from '../../images/home/contest.jpg'

import image5 from '../../images/home/fr_welcome.png'
import image6 from '../../images/home/fr_miller_lite.png'
import image7 from '../../images/home/fr_insurance.png'
import image8 from '../../images/home/fr_covering.png'

import covidUpdate from '../../images/home/covidupdate.png'

import UpcomingTournaments from '../components/UpcomingTournaments.vue'
import RegisterToTournament from '../components/RegisterToTournament.vue'
import axios from 'axios'

export default {
	metaInfo() {
        return { 
            title: "Slo-Pitch National",
            meta: [
                { name: 'description', content:  'Slo-Pitch National offers, to member teams, a Canada-wide qualifying tournament program leading to Regional, Provincial and National championships in over 22 category and calibre levels. Slo-Pitch National, along with a dedicated team of volunteers, administer, coordinate and regulate over 950 provincial qualifying tournaments each season across Canada.' },
				{ property: 'og:title', content: "Slo-Pitch National - Sanctioned Canadian-Wide Slo-Pitch Leagues & Tournaments"},
				{ property: 'og:url', content:  'https://www.slo-pitch.com/' },
				{ property: 'og:image', content: 'https://slo-pitch.com/uploads/facebook/main.png' },
                { property: 'og:site_name', content: 'Slo-Pitch National'},
                { property: 'og:type', content: 'website' },    
                { name: 'robots', content: 'index,follow' } 
            ]
        }
    },
	data() {
		return {
			carouselSize: 3,
		 	
			carousels2: [
                { image: covidUpdate, font_color: '#fff', text: 'Welcome to Slo-Pitch National', color: 'primary' },
                { image: covidUpdate, font_color: '#000', text: 'Home of the Molson Slo-Pitch Program', color: 'info' },
                { image: covidUpdate, font_color: '#fff', text: 'One Weekend. \n 5 Canadian Cities. \n 25 Championship Titles.', color: 'success' },
                { image: covidUpdate, font_color: '#fff', text: 'Join a league or tournament today!', color: 'warning' },
			],
			indicator: true,
            indicatorBackground: true,
			indicatorInside: true,
			en: true,
            indicatorMode: 'hover',
            indicatorPosition: 'is-bottom',
			indicatorStyle: 'is-lines',
			latestNews: [],
			test: 0,
            items: [
                {
                    title: 'Slide 1',
                    image: 'https://buefy.org/static/img/placeholder-1280x960.png',
                    rating: 4.4
                },
                {
                    title: 'Slide 2',
                    image: 'https://buefy.org/static/img/placeholder-1280x960.png',
                    rating: 3.5
                },
                {
                    title: 'Slide 3',
                    image: 'https://buefy.org/static/img/placeholder-1280x960.png',
                    rating: 5
                },
                {
                    title: 'Slide 4',
                    image: 'https://buefy.org/static/img/placeholder-1280x960.png'
                },
                {
                    title: 'Slide 5',
                    image: 'https://buefy.org/static/img/placeholder-1280x960.png',
                    rating: 5
                },
                {
                    title: 'Slide 6',
                    image: 'https://buefy.org/static/img/placeholder-1280x960.png',
                    rating: 4
                },
                {
                    title: 'Slide 7',
                    image: 'https://buefy.org/static/img/placeholder-1280x960.png',
                    rating: 2.7
                },
                {
                    title: 'Slide 8',
                    image: 'https://buefy.org/static/img/placeholder-1280x960.png',
                    rating: 1.5
                }
            ]
		};
	},
	components: {
		Header,
		Footer,
		UpcomingTournaments,
		RegisterToTournament
	},
	mounted: function() {

		var vm = this
		if(window.innerWidth < 800) {
			vm.carouselSize = 2
		}
		if(window.innerWidth < 600) {
			vm.carouselSize = 1
		}

		axios.get('/api/pages/latest-news').then(results => {
			if(results.data.success === true) {
				vm.latestNews = results.data.news

				var i = 0

				for(i; i < vm.latestNews.length; i++) {
					var content = JSON.parse(vm.latestNews[i].content)

					var div = document.createElement('div');
					if(content[0].columns[0].content) {
						div.innerHTML = content[0].columns[0].content;
					} else {
						div.innerHTML = content[0].columns[1].content;
					}
					
					var firstImage = div.getElementsByTagName('img')[0]
					var imgSrc = firstImage ? firstImage.src : "";
					// or, if you want the unresolved src, as it appears in the original HTML:
					var rawImgSrc = firstImage ? firstImage.getAttribute("src") : "";

					vm.latestNews[i].image = null
					if(rawImgSrc !== "") {
						vm.latestNews[i].image = rawImgSrc
					}

					var s = 0

					vm.latestNews[i].description = ""

					for(s; s < 3; s++) {
						if(div.getElementsByTagName('p')[s]) {
							if(!div.getElementsByTagName('p')[s].innerHTML.includes('<img')) {
								if(div.getElementsByTagName('p')[s].innerHTML !== 'undefined') {
									vm.latestNews[i].description += '<p class="latest-news-p">' + div.getElementsByTagName('p')[s].innerHTML + '</p>'
								}
							}
						}
					}

					var div2 = document.createElement('div');
					if(content[1]) {
						div2.innerHTML = content[1].columns[0].content;
						

						var ss = 0

						for(ss; ss < 3; ss++) {
							if(div2.getElementsByTagName('p')[ss]) {
								if(!div2.getElementsByTagName('p')[ss].innerHTML.includes('<img')) {
									if(div2.getElementsByTagName('p')[ss].innerHTML !== 'undefined') {
										// if(div2.getElementsByTagName('p')[ss].innerHTML)
										vm.latestNews[i].description += '<p class="latest-news-p">' + div2.getElementsByTagName('p')[ss].innerHTML + '</p>'
									}
								}
							}
						}
					}
				}
			}
		})
		
	},
	computed: {
		carousels: function() {
			if(this.en === true) {
				return [
					{ image: image0, href: 'https://shop.slo-pitch.com' },
					{ image: image1, href: '/login' },
					{ image: image2, href: '/insurance' },
					
					
				]
			}
			if(this.en === false) {
				return [
					{ image: image0, href: 'https://shop.slo-pitch.com' },
					{ image: image1, href: '/login' },
					{ image: image7, href: '/insurance' },
					
					
				]
			}
		} 
	},
	methods: {
		updateLanguage: function($event) {
			this.en = $event
			
		}
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
