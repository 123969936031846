<template>
	<div>
		<Header @update-lang="updateLanguage($event)"></Header>
        <div class="content-area" style="min-height: 600px;" v-if="open === false">
            <div class="frontend-content-wrapper">
                <h1>Umpire Exam Is Currently Closed</h1>
                <p>Please visit back February 1st</p>
            </div>
        </div>
        <div class="content-area" style="min-height: 600px;" v-if="open">
            <div class="frontend-content-wrapper">
                <h1>Umpire Exam</h1>
                <p>Please complete all questions to the best of your ability. You will be given 25 questions to answer in <b>true</b> or <b>false</b> format.</p>
                <br>
                <a class="admin-button" v-on:click="examStep = 'running'; en = true" v-if="examStep === 'start'">Start The Exam in English</a>
                <a class="admin-button" v-on:click="examStep = 'running'; en = false" v-if="examStep === 'start'">Start The Exam in French</a>
                <div class="question-group" v-if="examStep === 'running'">
                    <div class="exam-results-box" v-if="currentQuestion > 0">
                        <h5 style="margin-top: 0px;">Previous Question Results:</h5>
                        <p>
                            <b>You answered: </b> 
                            <span v-if="questions[currentQuestion-1].selected_answer === 1">True</span>
                            <span v-if="questions[currentQuestion-1].selected_answer === 0">False</span>
                        </p>
                        <p>
                            <b>Correct answer is: </b> 
                            <span v-if="questions[currentQuestion-1].correct_answer === 1">True</span>
                            <span v-if="questions[currentQuestion-1].correct_answer === 0">False</span>
                        </p>
                        <br>
                        <p><b>Current score:</b> {{ correctAnswers }}/{{ currentQuestion }}</p>
                        
                    </div>
                    <div class="question-box">
                        <div class="question">
                            
                            <div v-if="en === true">
                                <h5><b>Question {{ currentQuestion + 1 }}</b></h5>
                                <p><b>{{ questions[currentQuestion].subject }}</b></p>
                                <div v-html="questions[currentQuestion].text"></div>
                                <p><b>{{ questions[currentQuestion].rulebook_section }}</b></p>
                                <p>Ref ID: {{ questions[currentQuestion].id }}</p>
                                <!-- {{ questions[currentQuestion].correct_answer }} -->
                            </div>
                            <div v-if="en === false">
                                <h5><b>Question {{ currentQuestion + 1 }}</b></h5>
                                <p><b>{{ questions[currentQuestion].fr_subject }}</b></p>
                                <div v-html="questions[currentQuestion].fr_text"></div>
                                <p><b>{{ questions[currentQuestion].rulebook_section }}</b></p>
                                <p>Ref ID: {{ questions[currentQuestion].id }}</p>
                            </div>
                        </div>
                        
                        <div class="field" style="margin-top: 20px;">
                            
                            <b-radio v-model="questions[currentQuestion].selected_answer"
                                size="is-large"
                                :native-value="1">
                                <span v-if="en === true">True</span>
                                <span v-if="en === false">Vrai</span>
                            </b-radio>
                        </div>
                        <div class="field">
                            <b-radio v-model="questions[currentQuestion].selected_answer"
                                size="is-large"
                                :native-value="0">
                                <span v-if="en === true">False</span>
                                <span v-if="en === false">Faux</span>
                            </b-radio>
                        </div>
                        
                        <a class="admin-button" v-on:click="submitAnswer()" v-if="questions[currentQuestion].selected_answer !== null">Submit Answer</a>
                        <p class="note">{{ errorMessage }}</p>
                        
                        <br><br><br>
                        <br><br><br>
                        <br><br><br>
                        <a class="admin-button" style="background: #333;" v-on:click="retryTheTest()">Reset The Test</a>
                        
                    </div>
                </div>
                <div class="results-page" v-if="examStep === 'completed'">
                    <div class="exam-results-box" v-if="currentQuestion > 0">
                        <h5 style="margin-top: 0px;">Previous Question Results:</h5>
                        <p>
                            <b>You answered: </b> 
                            <span v-if="questions[currentQuestion-1].selected_answer === 1">True</span>
                            <span v-if="questions[currentQuestion-1].selected_answer === 0">False</span>
                        </p>
                        <p>
                            <b>Correct answer is: </b> 
                            <span v-if="questions[currentQuestion-1].correct_answer === 1">True</span>
                            <span v-if="questions[currentQuestion-1].correct_answer === 0">False</span>
                        </p>
                        <br>
                        <p><b>Current score:</b> {{ correctAnswers }}/{{ currentQuestion }}</p>
                        
                    </div>
                    <a class="admin-button" style="margin-top: 20px; background: #333;" v-on:click="wrongAnswersBox = !wrongAnswersBox">Click here to review where you went wrong</a>
                    <div class="wrong-answers" v-if="wrongAnswersBox">
                        <div class="review-question-box" style="margin-top: 20px;" v-for="(question, key) in wrongAnswers" :key="key">
                            <div v-if="en === true">
                                <p><b>{{ question.subject }}</b></p>
                                <div v-html="question.text"></div>
                                <p><b>{{ question.rulebook_section }}</b></p>

                                <p><b>You answered:</b> {{ question.selected_answer === 1 }}</p>
                                <p><b>Correct answer:</b> {{ question.correct_answer === 1 }}</p>
                            </div>
                            <div v-if="en === false">
                                <p><b>{{ question.fr_subject }}</b></p>
                                <div v-html="question.fr_text"></div>
                                <p><b>{{ question.rulebook_section }}</b></p>
                                <p><b>You answered:</b> {{ question.selected_answer === 1 }}</p>
                                <p><b>Correct answer:</b> {{ question.correct_answer === 1 }}</p>
                            </div>
                            <hr>
                        </div>
                   
                    </div>
                    <h3>Final Results of the Quiz:</h3>
                    <p><b>Your final score is: {{ (computedScore*100).toFixed(2) }}%</b></p>

                    

                    <div v-if="computedScore >= passingGrade">
                        <p>Congratulations! You passed!</p>
                        <p>Now that you have completed the umpire exam, it's time to attach this to your account and pay for your umpire package.</p>
                        <br>
                        <LoginRegister
                            v-if="authUser === null"
                            :redirect="false"
                            :redirect-to="false"
                            v-on:logged:in="triggerAuth($event)">
                        </LoginRegister>
                        
                        
                        <NewPaymentProcessor
                            v-if="authUser !== null"
                            :productId="product_id"
                            :regionId="authUser.default_region_id"
                            v-on:payment:successful="paymentSuccessSwitch($event)"
                            :teamName="authUser.email"
                            >
                        </NewPaymentProcessor>
                        

                        <div v-if="everythingSubmitted === false">
                            <a 
                                class="admin-button" 
                                style="margin-top: 20px;" 
                                v-on:click="submitResults()"
                                v-if="paymentSuccess">
                                <i class="fas fa-save"></i>Submit and Receive Your Certificate
                            </a>    
                        </div>
                        <div class="everything-submitted" v-if="everythingSubmitted === true">
                            <h3>You have submitted your final results.</h3>
                            <div v-if="paymentSuccess">
                                <div v-if="paymentSuccess.order.payment_type_id === 0">
                                    <p>Your submission is completed and approved. You will receive an email shortly confirming your certificate. Check out the membership dashboard to see your Certficiate and your Umpire Tools!</p>
                                </div>
                                <div v-if="paymentSuccess.order.payment_type_id !== 0">
                                    <p>Your submission will be considered unapproved until you complete your payment and confirm it. Please check out the membership dashboard when approved to view your Umpire Certificate.</p>
                                </div>
                                <a href="/dashboard" class="admin-button">Go to your dashboard</a>
                            </div>
                        </div>
                    </div>
                    <div v-if="computedScore < passingGrade">
                        <p>Sorry, you did not get enough correct answers in order to recieve certification. You can retry the test at any time though!</p>
                        <br>
                        <a class="admin-button" v-on:click="retryTheTest()">Retry the Test</a>
                    </div>
                    
                </div>
            </div>
        </div>
		<Footer></Footer>
	</div>
</template>

<script>

import Header from './components/UmpireHeader.vue'
import Footer from './components/UmpireFooter.vue'

import NewPaymentProcessor from '../components/NewPaymentProcessor.vue'
import PaymentProcessor from '../components/PaymentProcessor.vue'
import LoginRegister from '../components/LoginRegister.vue'

import axios from 'axios'

export default {
    metaInfo() {
        return { 
            title: "Take the Exam - Slo-Pitch National",
            meta: [
                { name: 'description', content:  'Click here to take the umpire exam and learn  more if umpiring is right for you!' },
                { property: 'og:title', content: "Take the Exam - Slo-Pitch National"},
                { property: 'og:url', content:  'https://www.slo-pitch.com/umpires/take-the-exam/' },
                { property: 'og:site_name', content: 'Slo-Pitch National'},
                { property: 'og:image', content: 'https://slo-pitch.com/uploads/facebook/taketheexam.png' },
                { property: 'og:type', content: 'website' },    
                { name: 'robots', content: 'index,follow' } 
            ]
        }
    },
	data() {
		return {
            examStep: 'start',
            en: true,
            open: true,
            lastAnswerCorrect: null,
            currentQuestion: 0,
            correctAnswers: 0,
            product_id: 9,
            passingGrade: 0.80,
            allQuestionsComplete: false,
            errorMessage: null,
            questions: [],
            resetQuestions: [],
            congratsEmail: null,
            paymentSuccess: false,
            everythingSubmitted: false,
            check_umpire_permissions: null,
            card: {
                number: null,
                month: '01',
                year: 2021,
                cvc: null
            },
            authUser: null,
            wrongAnswersBox: false
		};
	},
	components: {
		Header,
        Footer,
        PaymentProcessor,
        LoginRegister,
        NewPaymentProcessor
    },
    watch: {
        paymentSuccess: async function($event) {
            
            // now trigger automatically Submit & Receive Your Certificate

            // await this.submitResults()
        }
    },
	mounted: function() {
        // questions need to be pulled from the database
        var vm = this

        const start = 9 * 60;
        const end = 17 * 60;
        const date = new Date(); 
        const now = date.getHours() * 60 + date.getMinutes();
        const day_of_week = date.getDay()

        if(start <= now && now <= end) {
            // console.log('Chat is hidden.')
        } else {
            $('#front-chat-container').hide()    
        }
        // if(day_of_week === 5) {
        //     $('#front-chat-container').hide()    
        // }
        if(day_of_week === 6) {
            $('#front-chat-container').hide()    
        }
        if(day_of_week === 0) {
            $('#front-chat-container').hide()    
        }

        if(sessionStorage.getItem('lang')) {    
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }


        // Add exam status check
        axios.get('/api/umpires/check-exam-toggle').then(examResults => {
            console.log(examResults)
            if(examResults.data.success) {
                vm.open = examResults.data.enabled;
            }
        });


        axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.authUser = results.data.user
                vm.congratsEmail = results.data.user.email
                // get the region rate for the umpire
            }
        })

        axios.get('/api/umpires/check-for-previous-results').then(results => {
            if(results.data.success === true) {
                vm.check_umpire_permissions = results.data.check_for_umpire_permissions
                if(vm.check_umpire_permissions) {
                    vm.product_id = 9
                }
            }
        })

		axios.get('/api/umpires/exam-questions').then(results => {
            if(results.data.success === true) {
                vm.questions = results.data.questions
                if(window.sessionStorage.getItem('exam')) {
                    var exam = window.sessionStorage.getItem('exam')
                    var parsed = JSON.parse(exam)
                    vm.lastAnswerCorrect = parsed.lastAnswerCorrect
                    vm.questions = parsed.questions
                    vm.correctAnswers = parsed.correctAnswers
                    vm.currentQuestion = parsed.currentQuestion
                    vm.allQuestionsComplete = parsed.allQuestionsComplete
                    vm.congratsEmail = parsed.congratsEmail
                    vm.examStep = parsed.examStep
                }
            }
        })

        
	},
	computed: {
        computedScore: function() {
            var vm = this

            return this.correctAnswers/this.questions.length
        },
        wrongAnswers: function() {
            var vm = this

            var questions = vm.questions

            questions = _.filter(questions, function(question) {
                if(question.correct_answer === question.selected_answer) {
                    return false
                }
                return true
            })


            return questions
            
        }
	},
	methods: {
        submitAnswer: function() {
            var vm = this
            if(vm.questions[vm.currentQuestion].selected_answer !== null) {
                // checks the correct answer vs selected answer
                // submits the score
                vm.errorMessage = null
                if(vm.questions[vm.currentQuestion].correct_answer === vm.questions[vm.currentQuestion].selected_answer) {
                    vm.correctAnswers++
                }

                vm.currentQuestion++

                if(vm.currentQuestion === vm.questions.length) {
                    vm.examStep = 'completed'

                    if(vm.computedScore < vm.passingGrade) {
                        
                    }
                }

                var data = JSON.stringify({
                    lastAnswerCorrect: vm.lastAnswerCorrect,
                    questions: vm.questions,
                    correctAnswers: vm.correctAnswers,
                    currentQuestion: vm.currentQuestion,
                    allQuestionsComplete: vm.allQuestionsComplete,
                    congratsEmail: vm.congratsEmail,
                    examStep: vm.examStep
                })

                window.sessionStorage.setItem('exam', data)
                

            } else {
                vm.errorMessage = "You forgot to select an answer!"
            }
            
        },
        retryTheTest: function() {
            var vm = this
            vm.examStep = 'start'
            vm.lastAnswerCorrect = null
            vm.currentQuestion = 0
            vm.correctAnswers = 0

            var i = 0

            for(i; i < vm.questions.length; i++) {
                vm.questions[i].selected_answer = null
            }

            window.sessionStorage.removeItem('exam')
        },
        submitResults: async function() {
            var vm = this

            // format the questions area smaller then submit
            var question_results = []

            var i = 0

            for(i; i < vm.questions.length; i++) {
                var question = vm.questions[i]
                question_results.push({
                    id: question.id,
                    correct_answer: question.correct_answer,
                    selected_answer: question.selected_answer,
                    required: question.required
                })
            }

            axios.post('/api/umpires/submit-results', {
                correct_answers: vm.correctAnswers,
                user_id: vm.authUser.id,
                question_results: question_results,
                order: vm.paymentSuccess.order.id
            }).then(results => {
                if(results.data.success === true) {
                    // now submit the results
                    vm.everythingSubmitted = true

                    window.sessionStorage.removeItem('exam')

                    this.$buefy.snackbar.open({
                        message: 'Your info has been submitted and is awaiting approval! Great score!',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                }
            })
                   
        },
        paymentSuccessSwitch: function($event) {
            var vm = this
            var json = JSON.stringify($event)

            vm.paymentSuccess = $event

            vm.$nextTick(() => {
                this.submitResults()
            })
        },
        triggerAuth: function() {
            var vm = this
            axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.authUser = results.data.user
                vm.congratsEmail = results.data.user.email

                    // get the region rate for the umpire

                }
            })

        },
        updateLanguage: function($event) {
			this.en = $event
			
		}
    }
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
